const Services3 = [
  {
    Id: "1",
    sTitle: "특허 출원 마감일 관리",
  },
  {
    Id: "2",
    sTitle: "원스탑 출원 서비스",
  },
  {
    Id: "3",
    sTitle: "특허 출원 전략 제안",
  },
  {
    Id: "4",
    sTitle: "특허 출원 전문 변리사 매칭",
  },
];
export default Services3;
